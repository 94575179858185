// Google Tag Manager snippet
export const GTMScript = `
    (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl + "&gtm_auth=${process.env.VUE_APP_GTM_AUTH}&gtm_preview=${process.env.VUE_APP_GTM_PREVIEW}&gtm_cookies_win=x";
        f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", "GTM-PPGRR3W");
    `;

export function setGTMScriptTag() {
  if (process.env.VUE_APP_GTM_AUTH && process.env.VUE_APP_GTM_PREVIEW) {
    const script = document.createElement("script");
    script.innerHTML = GTMScript;
    script.setAttribute("id", "GTM");

    document.head.appendChild(script);

    const iframe = document.createElement("iframe");
    iframe.setAttribute(
      "src",
      `https://www.googletagmanager.com/ns.html?id=GTM-PPGRR3W&gtm_auth=${process.env.VUE_APP_GTM_AUTH}&gtm_preview=${process.env.VUE_APP_GTM_PREVIEW}&gtm_cookies_win=x`
    );
    iframe.setAttribute("height", 0);
    iframe.setAttribute("width", 0);
    iframe.setAttribute("style", "display:none;visibility:hidden");

    const noscript = document.createElement("noscript");
    noscript.appendChild(iframe);

    document.body.prepend(noscript);
  }
}
