import { createStore } from 'vuex'

// simple obfuscation to store user id in localStorage
function obfuscateUid(uid) {
  const prefix = Math.random()
    .toString(36)
    .substr(2, 7);
  const suffix = Math.random()
    .toString(36)
    .substr(2, 7);

  return `${prefix}-${uid}-${suffix}`;
}

function getUid() {
  const uid = localStorage.getItem("uid");
  if (!uid) return null;

  return uid.slice(8, 44);
}

export default createStore({
  state: {
    user: { email: localStorage.getItem("email"), id: getUid(), token: localStorage.getItem("token") },
    tour: {
      currentStepId:
        localStorage.getItem("currentStepId") && Number(localStorage.getItem("currentStepId")) > -1
          ? Number(localStorage.getItem("currentStepId"))
          : -1,
      tourStarted: localStorage.getItem("tourStarted") && localStorage.getItem("tourStarted") === "true",
    },
  },
  getters: {
    authenticated: state => state.user.token !== null,
    token: state => state.user.token,
    user: state => state.user,
    tour: state => state.tour,
  },
  mutations: {
    removeUser(state) {
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("uid");
      localStorage.removeItem("infoBarVisibility");
      state.user = { email: null, id: null, token: null };
    },
    setToken(state, token) {
      localStorage.setItem("token", token);
      state.user.token = token;
    },
    setUser(state, user) {
      localStorage.setItem("token", user.token);
      localStorage.setItem("email", user.email);
      localStorage.setItem("uid", obfuscateUid(user.id));
      state.user = user;
    },
    setTour(state, tour) {
      // had to add this to localstorage to fix the issue when navigate away for source integrations page(s) and redirect back we lose the current tour store value
      localStorage.setItem("currentStepId", tour.currentStepId);
      localStorage.setItem("tourStarted", tour.tourStarted);
      state.tour = tour;
    },
    setCurrentStepId(state, currentStepId) {
      localStorage.setItem("currentStepId", currentStepId);
      state.tour.currentStepId = currentStepId;
    },
  },
  actions: {},
  modules: {},
})


