import { defineComponent, reactive } from 'vue';
import Error from '@/mixins/Error.js';

export default defineComponent({
  data() {
    return {
      dynamicState: reactive({ loading: false }),
    };
  },
  methods: {
    clearCookies() {
      document.cookie = "console=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
    cpuUsage(value) {
      return `${(value * 1000).toFixed(0)}m`;
    },
    ttlMinutesToString() {
      const ttl = process.env.VUE_APP_CLI_TOKEN_TTL_MINUTE || 1440;
      if (ttl <= 60) return ttl + "m";
      const h = Math.floor(ttl / 60) + "h";
      const m = ttl % 60 > 0 ? " " + (ttl % 60) + "m" : "";
      return h + m;
    },
    locationHash() {
      return window.location.hash ? window.location.hash.slice(1) : null;
    },
    memUsage(value) {
      return `${value.toFixed(0)}MB`;
    },
    percentageString(value) {
      return `${value.toFixed(2)}%`;
    },
    async mutateWrapper(mutateOpts, loadingVar = "loading") {
      if (!(loadingVar in this.dynamicState)) {
        this.dynamicState[loadingVar] = false;
      }

      this.dynamicState[loadingVar] = true;
      let errorMsg = null;
      let resp = null;

      try {
        resp = await this.$apollo.mutate(mutateOpts);
      } catch (err) {
        errorMsg = this.graphQLErrors(err);
      }

      this.dynamicState[loadingVar] = false;
      return { resp, errorMsg };
    },
    bufferDecode(value) {
      // Base64 to ArrayBuffer
      return Uint8Array.from(atob(value), c => c.charCodeAt(0));
    },
    bufferEncode(value) {
      // ArrayBuffer to URLBase64
      return btoa(String.fromCharCode.apply(null, new Uint8Array(value)))
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=/g, "");
    },
  },
  mixins: [Error],
});
