import { ApolloClient, InMemoryCache, HttpLink, split } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createApolloProvider } from '@vue/apollo-option';
import { RetryLink } from 'apollo-link-retry';
import { setContext } from '@apollo/client/link/context';
import * as Sentry from '@sentry/vue';
import Utils from '@/mixins/Utils.js';
import ErrorHelper from '@/mixins/Error.js';

const AUTH_TOKEN = 'token';
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || `https://${window.location.host}/graphql`;

export const filesRoot = process.env.VUE_APP_FILES_ROOT || httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'));

// RetryLink setup
const retryLink = new RetryLink({
  delay: {
    initial: 200,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
  },
});

// Set up dynamic HTTP headers
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const httpLink = new HttpLink({
  uri: httpEndpoint,
});

const wsLink = new WebSocketLink({
  uri: process.env.VUE_APP_GRAPHQL_WS || `wss://${window.location.host}/graphql`,
  options: {
    reconnect: true,
    connectionParams: () => ({
      Authorization: localStorage.getItem(AUTH_TOKEN) ? `Bearer ${localStorage.getItem(AUTH_TOKEN)}` : "",
    }),
  },
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  retryLink.concat(authLink.concat(httpLink)) // Combine authLink and retryLink
);

const defaultOptions = {
  link,
  cache: new InMemoryCache(),
};

export function createProvider(options = {}) {
  const apolloClient = new ApolloClient({
    ...defaultOptions,
    ...options,
  });
  apolloClient.wsLink = wsLink;

  const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        errorPolicy: 'all',
      },
    },
    errorHandler(error) {
      if (process.env.VUE_APP_ENVIRONMENT === 'development') {
        // eslint-disable-next-line no-console
        console.log('%cError','background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',JSON.stringify(error));
      }

      if (ErrorHelper.methods.graphQLErrorCode(error, 'UNAUTHENTICATED')) {
        if (this.$route.path === '/login' || this.$route.path === '/signup') return;
        window.location.href = '/login';
        return;
      } else if (ErrorHelper.methods.graphQLErrorCode(error, 'INVALID_ROUTE_DESTINATION')) {
        Utils.methods.clearCookies();
        if (typeof localStorage !== 'undefined') {
          localStorage.removeItem(AUTH_TOKEN);
        }
        window.location.href = '/login';
        return;
      } else {
        Sentry.captureException(error, {
          extra: { 'graphql.query.raw': JSON.stringify(error.gqlError) },
        });
      }
    },
  });

  return apolloProvider;
}

export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  if (apolloClient.wsLink) {
    apolloClient.wsLink.subscriptionClient.close(false, false);
    apolloClient.wsLink.subscriptionClient.connect();
  }
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

export async function onLogout(apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN);
  }
  document.cookie = 'opt-in=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  if (apolloClient.wsLink) {
    apolloClient.wsLink.subscriptionClient.close(false, false);
    apolloClient.wsLink.subscriptionClient.connect();
  }
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
}
