<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");
@import "@/styles/bootstrap.scss";

@import "~bootstrap";
@import "~bootstrap-vue";

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/v4-shims";

@import "@/styles/constant.scss";
@import "@/styles/animation.scss";
@import "@/styles/app.scss";
@import "@/styles/color.scss";
@import "@/styles/body.scss";
@import "@/styles/card.scss";
@import "@/styles/clickable.scss";
@import "@/styles/logs.scss";
@import "@/styles/modal.scss";
@import "@/styles/nav.scss";
@import "@/styles/rack.scss";
@import "@/styles/spinner.scss";
@import "@/styles/table.scss";
@import "@/styles/workflows.scss";
</style>

<script>
import * as Sentry from "@sentry/vue";
import { setGTMScriptTag } from "@/scripts/gtm";
export default {
  async mounted() {
    const { email, id } = this.$store.getters.user;
    Sentry.setUser({ id, email });

    try {
      const res = await this.$apollo.query({ query: require("@/queries/License.graphql") });
      const { license } = res.data;
      if (license.public) {
        setGTMScriptTag();
      }
    } catch {
      return; // don't break the app if we fail to get the license
    }
  },
};
</script>
