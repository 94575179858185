import "regenerator-runtime/runtime"; // https://github.com/Akryum/vue-cli-plugin-apollo/issues/355
import { createApp, h } from "vue";
import * as Sentry from "@sentry/vue";
import Analytics from "./mixins/Analytics";
import router from "./router";
import store from "./store";
import { createProvider } from "./vue-apollo";
import BootstrapVue from "bootstrap-vue";
import "bootstrap";
import Vue3Tour from "vue3-tour";
import timeago from "vue-timeago3";
import "vue3-tour/dist/vue3-tour.css";
import App from "./App.vue";

const app = createApp({ render: () => h(App) });

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    ],
    tracesSampleRate: 1.0,
    environment: process.env.VUE_APP_ENVIRONMENT,
  });
}


app.use(BootstrapVue);
app.use(createProvider());
app.use(store);
app.use(router);
app.use(Vue3Tour);
app.use(timeago, {
  name: "Timeago",
});
app.mixin(Analytics);
app.mount("#app");
