import { createRouter, createWebHistory } from 'vue-router'

import Blank from "@/views/Private/Organization/Blank.vue";


const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
    meta: { public: true },
  },
  {
    path: "/",
    component: () => import(/* webpackChunkName: "public" */ "@/views/Public.vue"),
    children: [
      {
        path: "/error",
        name: "error",
        component: () => import(/* webpackChunkName: "public/login" */ "@/views/Public/error.vue"),
        meta: { public: true },
      },
      {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "public/login" */ "@/views/Public/Login.vue"),
        meta: { public: true },
      },
      {
        path: "/sessions/new",
        redirect: "/login",
      },
      {
        path: "/signup",
        name: "signup",
        component: () => import(/* webpackChunkName: "public/login" */ "@/views/Public/Signup.vue"),
        meta: { public: true },
      },
      {
        path: "/password",
        name: "password",
        component: () => import(/* webpackChunkName: "password" */ "@/views/Public/Password.vue"),
        meta: { public: true },
        children: [
          {
            path: "reset",
            name: "password/reset-request",
            component: () => import(/* webpackChunkName: "password/reset-request" */ "@/views/Public/Password/PasswordResetRequest.vue"),
            meta: { public: true },
          },
          {
            path: "reset/:uid/:token",
            name: "password/reset",
            component: () => import(/* webpackChunkName: "password/reset" */ "@/views/Public/Password/PasswordReset.vue"),
            meta: { public: true },
          },
        ],
      },
    ],
  },
  {
    path: "/",
    name: "private",
    component: () => import(/* webpackChunkName: "private" */ "@/views/Private.vue"),
    children: [
      // {
      //   path: "/account",
      //   name: "account",
      //   component: () => import(/* webpackChunkName: "public/login" */ "@/views/Private/Account.vue"),
      // },
      {
        path: "organizations/new",
        name: "organization/new",
        component: () => import(/* webpackChunkName: "organization/new" */ "@/views/Private/Organization/New.vue"),
      },
      {
        path: "/organizations/:oid",
        name: "organization",
        component: () => import(/* webpackChunkName: "organization" */ "@/views/Private/Organization.vue"),
        children: [
          {
            path: "audits",
            name: "organization/audits",
            component: () => import(/* webpackChunkName: "organization/audits" */ "@/views/Private/Organization/Audits.vue"),
            meta: { role: "operator" },
          },
          {
            path: "billing",
            name: "organization/billing",
            component: () => import(/* webpackChunkName: "organization/billing" */ "@/views/Private/Organization/Billing.vue"),
            meta: { role: "administrator" },
          },
          {
            path: "dashboard",
            name: "organization/dashboard",
            component: () => import(/* webpackChunkName: "organization/dashboard" */ "@/views/Private/Organization/Dashboard.vue"),
          },
          {
            path: "dashboard/racks/:rid",
            name: "organization/dashboard/rack",
            component: () =>
              import(/* webpackChunkName: "organization/dashboard/rack" */ "@/views/Private/Organization/Rack/Dashboard.vue"),
          },
          {
            path: "dashboard/racks/:rid/apps/:aid",
            name: "organization/dashboard/rack/app",
            component: () =>
              import(/* webpackChunkName: "organization/dashboard/rack/app" */ "@/views/Private/Organization/Rack/App/Dashboard.vue"),
          },
          {
            path: "integrations",
            name: "organization/integrations",
            component: () => import(/* webpackChunkName: "organization/integrations" */ "@/views/Private/Organization/Integrations.vue"),
            meta: { role: "operator" },
          },
          {
            path: "invitations/:id",
            name: "organization/invitations",
            component: () => import(/* webpackChunkName: "organization/new" */ "@/views/Private/Organization/Invitation.vue"),
            meta: { isInvite: true },
          },
          {
            path: "jobs",
            name: "organization/jobs",
            component: () => import(/* webpackChunkName: "organization/jobs" */ "@/views/Private/Organization/Jobs.vue"),
            meta: { role: "developer" },
            children: [
              {
                path: ":id",
                redirect: to => ({
                  name: "organization/jobs",
                  hash: `/#${to.params.id}`,
                }),
              },
            ],
          },
          {
            path: "racks",
            name: "organization/racks",
            component: () => import(/* webpackChunkName: "organization/racks" */ "@/views/Private/Organization/Racks.vue"),
            meta: { role: "developer" },
          },
          {
            path: "racks/:rid",
            name: "organization/rack",
            component: () => import(/* webpackChunkName: "organization/rack" */ "@/views/Private/Organization/Rack.vue"),
            meta: { role: "developer" },
            children: [
              {
                path: "apps",
                name: "organization/rack/apps",
                component: () => import(/* webpackChunkName: "organization/rack/apps" */ "@/views/Private/Organization/Rack/Apps.vue"),
                meta: { expand: true, role: "developer" },
              },
              {
                path: "instances",
                name: "organization/rack/instances",
                component: () =>
                  import(/* webpackChunkName: "organization/rack/instances" */ "@/views/Private/Organization/Rack/Instances.vue"),
                meta: { expand: true, role: "developer" },
              },
              {
                path: "logs",
                name: "organization/rack/logs",
                component: () => import(/* webpackChunkName: "organization/rack/logs" */ "@/views/Private/Organization/Rack/Logs.vue"),
                meta: { expand: true, role: "developer" },
              },
              {
                path: "processes",
                name: "organization/rack/processes",
                component: () =>
                  import(/* webpackChunkName: "organization/rack/processes" */ "@/views/Private/Organization/Rack/Processes.vue"),
                meta: { expand: true, role: "developer" },
              },
              {
                path: "resources",
                name: "organization/rack/resources",
                component: () =>
                  import(/* webpackChunkName: "organization/rack/resources" */ "@/views/Private/Organization/Rack/Resources.vue"),
                meta: { expand: true, role: "developer" },
              },
              {
                path: "settings",
                name: "organization/rack/settings",
                component: Blank,
                meta: { role: "developer" },
              },
              {
                path: "updates",
                name: "organization/rack/updates",
                component: () =>
                  import(/* webpackChunkName: "organization/rack/updates" */ "@/views/Private/Organization/Rack/Updates.vue"),
                meta: { expand: true, role: "developer" },
              },
            ],
          },
          {
            path: "racks/:rid/apps/:aid",
            name: "organization/rack/app",
            component: () => import(/* webpackChunkName: "organization/rack/app" */ "@/views/Private/Organization/Rack/App.vue"),
            meta: { expand: true, role: "developer" },
            beforeEnter: (to, from, next) => {
              switch (to.name) {
                case "organization/rack/app":
                  router.push({ name: "organization/rack/app/services", params: to.params });
                  break;
                default:
                  next();
              }
            },
            children: [
              {
                path: "builds",
                name: "organization/rack/app/builds",
                component: () =>
                  import(/* webpackChunkName: "organization/rack/app/builds" */ "@/views/Private/Organization/Rack/App/Builds.vue"),
                meta: { expand: true, role: "developer" },
              },
              {
                path: "processes",
                name: "organization/rack/app/processes",
                component: () =>
                  import(/* webpackChunkName: "organization/rack/app/processes" */ "@/views/Private/Organization/Rack/App/Processes.vue"),
                meta: { expand: true, role: "developer" },
              },
              {
                path: "releases",
                name: "organization/rack/app/releases",
                component: () =>
                  import(/* webpackChunkName: "organization/rack/app/releases" */ "@/views/Private/Organization/Rack/App/Releases.vue"),
                meta: { expand: true, role: "developer" },
              },
              {
                path: "services",
                name: "organization/rack/app/services",
                component: () =>
                  import(/* webpackChunkName: "organization/rack/app/services" */ "@/views/Private/Organization/Rack/App/Services.vue"),
                meta: { expand: true, role: "developer" },
              },
              {
                path: "environment",
                name: "organization/rack/app/environment",
                component: () =>
                  import(
                    /* webpackChunkName: "organization/rack/app/environment" */ "@/views/Private/Organization/Rack/App/Environment.vue"
                  ),
                meta: { expand: true, role: "developer" },
              },
            ],
          },
          {
            path: "settings",
            name: "organization/settings",
            component: () => import(/* webpackChunkName: "organization/settings" */ "@/views/Private/Organization/Settings.vue"),
            meta: { role: "administrator" },
          },
          {
            path: "support",
            name: "organization/support",
            component: () => import(/* webpackChunkName: "organization/support" */ "@/views/Private/Organization/Support.vue"),
            meta: { role: "developer" },
          },
          {
            path: "users",
            name: "organization/users",
            component: () => import(/* webpackChunkName: "organization/users" */ "@/views/Private/Organization/Users.vue"),
            meta: { role: "administrator" },
          },
          {
            path: "welcome",
            name: "organization/welcome",
            component: () => import(/* webpackChunkName: "organization/audits" */ "@/views/Private/Organization/Welcome.vue"),
          },
          {
            path: "workflows",
            name: "organization/workflows",
            component: () => import(/* webpackChunkName: "organization/workflows" */ "@/views/Private/Organization/Workflows.vue"),
            meta: { role: "operator" },
          },
          {
            path: "admin",
            name: "organization/admin",
            component: () => import(/* webpackChunkName: "organization/workflows" */ "@/views/Private/Organization/Admin.vue"),
            meta: { role: "administrator" },
            children: [
              {
                path: "organizations",
                name: "organization/admin/organizations",
                component: () =>
                  import(/* webpackChunkName: "organization/admin/apps" */ "@/views/Private/Organization/Admin/Organizations.vue"),
                meta: { expand: true, role: "administrator", requireSuperUser: true },
              },
              {
                path: "racks",
                name: "organization/admin/racks",
                component: () => import(/* webpackChunkName: "organization/admin/racks" */ "@/views/Private/Organization/Admin/Racks.vue"),
                meta: { expand: true, role: "administrator", requireSuperUser: true },
              },
              {
                path: "users",
                name: "organization/admin/users",
                component: () => import(/* webpackChunkName: "organization/admin/users" */ "@/views/Private/Organization/Admin/Users.vue"),
                meta: { expand: true, role: "administrator", requireSuperUser: true },
              },
              {
                path: "license",
                name: "organization/admin/license",
                component: () =>
                  import(/* webpackChunkName: "organization/admin/license" */ "@/views/Private/Organization/Admin/License.vue"),
                meta: { expand: true, role: "administrator", requireSuperUser: true },
              },
            ],
          },
        ],
      },
      {
        path: "sso/discourse",
        name: "sso/discourse",
        component: () => import(/* webpackChunkName: ""sso/discourse" */ "@/views/Private/Discourse.vue"),
      },
    ],
  },
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
})

export default router;

import { accessible } from "@/scripts/access";
import { createProvider } from "@/vue-apollo";
import store from "@/store";

const apollo = createProvider().defaultClient;

router.beforeEach(async (to, from, next) => {
  let reason = "";
  let email = "";
  if (to.meta.public) return next();
  if (to.meta.isInvite) {
    try {
      const { existing_user_invite } = (
        await apollo.query({
          query: require("@/queries/Organization/Invitation/ExistingUser.graphql"),
          variables: {
            id: to.params.id,
          },
        })
      ).data;
      const { invitation_email } = (
        await apollo.query({
          query: require("@/queries/Organization/Invitation/InvitationEmail.graphql"),
          variables: {
            id: to.params.id,
          },
        })
      ).data;
      email = invitation_email;
      if (existing_user_invite) {
        reason = "Login using the invited email to accept the Organization Invite";
      } else {
        reason = "Sign up using the invited email to accept the Organization Invite";
        return next({
          name: "signup",
          query: {
            redirect: to.fullPath,
            reason: reason,
            prefilled_email: email,
          },
        });
      }
    } catch (e) {
      reason = "";
    }
  }
  if (!store.getters.authenticated) {
    return next({
      name: "login",
      query: {
        redirect: to.fullPath,
        reason: reason,
        prefilled_email: email,
      },
    });
  }
  if (!to.meta.role) return next();
  if (to.name === "organization/billing") {
    //block direct url navigation for billing page for enterprise plan
    const { is_console_enterprise } = (
      await apollo.query({
        query: require("@/queries/IsConsoleEnterprise.graphql"),
        variables: {
          id: to.params.id,
        },
      })
    ).data;
    if (is_console_enterprise) {
      return next({
        name: "organization/racks",
        params: { oid: to.params.oid },
      });
    }
  }
  try {
    if (!to.params.oid) return next({ name: "login" });

    const { organization, user } = (
      await apollo.query({
        query: require("@/queries/OrganizationAndUser.graphql"),
        variables: {
          id: to.params.oid,
        },
      })
    ).data;

    if (!accessible(to.meta.role, organization.access)) {
      return next({
        name: "organization/racks",
        params: { oid: organization.id },
      });
    }

    if (to.meta.requireSuperUser && !user.superuser) {
      return next({
        name: "organization/racks",
        params: { oid: organization.id },
      });
    }

    next();
  } catch {
    next({ name: "login" });
  }
});
