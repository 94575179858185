import * as Sentry from "@sentry/vue";

export const events = {
  ACCOUNT_SHOW: "account-show",
  ACCOUNT_UPDATE: "account-update",
  ACCOUNT_UPDATE_PASSWORD: "account-update-password",
  ADMIN: "admin",
  ADMIN_ORGANIZATIONS: "admin-organizations",
  ADMIN_RACKS: "admin-racks",
  ADMIN_USERS: "admin-users",
  ADMIN_LICENSE: "admin-license",
  APP_CREATE: "app-create",
  APP_DELETE: "app-delete",
  APP_GET: "app-get",
  APP_UPDATE: "app-update",
  AUDIT_LIST: "audit-list",
  BILLING_SHOW: "billing-show",
  DASHBOARD_OVERVIEW: "dashboard-overview",
  DASHBOARD_RACK: "dashboard-rack",
  INTEGRATION_DELETE: "integration-delete",
  INTEGRATION_NEW: "integration-new",
  INTEGRATION_LIST: "integration-list",
  INTEGRATION_REFRESH: "integration-refresh",
  INVITATION_ACCEPT: "invitation-accept",
  INVITATION_CREATE: "invitation-create",
  INVITATION_DELETE: "invitation-delete",
  INVITATION_REJECT: "invitation-reject",
  INVITATION_GET: "invitation-get",
  JOB_CANCEL: "job-cancel",
  JOB_LIST: "job-list",
  JOB_RERUN: "job-rerun",
  JOB_SHOW: "job-show",
  KEY_DELETE: "key-delete",
  KEY_CREATE: "key-create",
  LOGIN: "login",
  ORGANIZATION_CREATE: "organization-create",
  ORGANIZATION_UPDATE: "organization-update",
  ORGANIZATION_DELETE: "organization-delete",
  ORGANIZATION_GET: "organization-get",
  RACK_ADD: "rack-add",
  RACK_CLONE: "rack-clone",
  RACK_DELETE: "rack-delete",
  RACK_GET: "rack-get",
  RACK_INSTALL: "rack-install",
  RACK_LIST: "rack-list",
  RACK_UPDATE: "rack-update",
  RELEASE_PROMOTE: "release-promote",
  RELEASE_ROLLBACK: "release-rollback",
  ROOT: "root", // root website url
  SETTINGS_SHOW: "settings-show",
  SIGNUP: "signup",
  TICKET_COMMENT: "ticket-comment",
  TICKET_CLOSE: "ticket-close",
  TICKET_CREATE: "ticket-create",
  TICKET_LIST: "ticket-list",
  TOKEN_DELETE: "token-delete",
  TOKEN_REGISTER_REQUEST: "token-register-request",
  TOKEN_REGISTER_RESPONSE: "token-register-response",
  USER_CONSOLE_BETA_ON: "user-console-beta-on",
  USER_CONSOLE_BETA_OFF: "user-console-beta-off",
  USER_LIST: "user-list",
  USER_REMOVE: "user-remove",
  USER_UPDATE: "user-update",
  WELCOME: "welcome",
  WORKFLOW_CREATE: "workflow-create",
  WORKFLOW_DELETE: "workflow-delete",
  WORKFLOW_GET: "workflow-get",
  WORKFLOW_LIST: "workflow-list",
  WORKFLOW_RUN: "workflow-run",
  WORKFLOW_UPDATE: "workflow-update",
};

const pathNameToEventName = {
  organization: events.ORGANIZATION_GET,
  // "organization/admin": events.ADMIN,
  // "organization/admin/organizations": events.ADMIN_ORGANIZATIONS,
  // "organization/admin/racks": events.ADMIN_RACKS,
  // "organization/admin/users": events.ADMIN_USERS,
  // "organization/admin/license": events.ADMIN_LICENSE,
  "organization/audits": events.AUDIT_LIST,
  "organization/billing": events.BILLING_SHOW,
  "organization/dashboard": events.DASHBOARD_OVERVIEW,
  "organization/integrations": events.INTEGRATION_LIST,
  "organization/invitations": events.INVITATION_GET,
  "organization/jobs": events.JOB_LIST,
  "organization/racks": events.RACK_LIST,
  "organization/rack/app": events.APP_GET,
  "organization/rack": events.DASHBOARD_RACK,
  "organization/settings": events.SETTINGS_SHOW,
  "organization/support": events.TICKET_LIST,
  "organization/users": events.USER_LIST,
  "organization/workflows": events.WORKFLOW_LIST,
  signup: events.SIGNUP,
  welcome: events.WELCOME,
  "/": events.ROOT,
};

export default {
  // tracking is done on this hook instead of the global beforeEach hook
  // because the analytics script is embedded within google tag manager bundle
  // and the bundle is only added to the DOM after App.vue is mounted.
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const eventName = pathNameToEventName[to.name];
      if (eventName) {
        vm.eventTrack(eventName);
      }
    });
  },
  data() {
    return {
      events,
    };
  },

  methods: {
    eventTrack(name, context = {}) {
      if (name) {
        const organizationId = this.$route.params.oid;
        if (organizationId) {
          context["organization_id"] = organizationId;
        }

        const { user } = this.$store.getters;
        context["email"] = user.email;
        context["user_source"] = "ui";

        window.analytics && window.analytics.track(name, context);
      }
    },
    identify(user = null) {
      if (!user) {
        user = this.$store.getters.user;
      }
      if (window.analytics != undefined) {
        window.analytics.identify(user.id, { email: user.email });
        window.analytics.alias(user.id, window.analytics.user().anonymousId());
      }
      Sentry.setUser({ id: user.id, email: user.email });
    },
    page() {
      window.analytics && window.analytics.page();
    },
  },
};
